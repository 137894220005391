import React from 'react';
import './Components.css';

class TermsOfUse extends React.Component {
  render() {
    return (
      <div>
        {window.location.href=process.env.PUBLIC_URL+"/terms/index.html"}
      </div>
    );
   }
}

export default TermsOfUse;