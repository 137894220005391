import React from "react";
import "./App.css";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import GetStarted from "./components/GetStarted";
import Download from "./components/Download";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App container-fluid">
      <div className="d-none d-md-block d-sm-none"></div>
      <Header />
      <Hero />
      <Features />
      {/* <GetStarted/>
      <Download/> */}
      <AboutUs />
      <Footer />
    </div>
  );
}

export default App;
