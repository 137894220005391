import React from 'react';
import './Components.css';

function AboutUs() {
  return (
    <div className="row about-us">
      <div className="side-margin hide-sm">
        <img src="badge.4.aboutus.svg" alt="badge4" className="side-badge img-fluid"/>
      </div>
      <div className="side-badge-sm hide-md">
        <img src="badge.4.aboutus.svg" alt="badge4" className="side-badge-sm img-fluid"/>
      </div>
      <div className="col-sm-10">
        <div className="about-us-text">
          <div className="row made-with">
            Made with <span className="love ml-md-3">❤︎</span> in Vancouver
          </div>
          <div className="row mission">
            Our mission is to help Canadians achieving financial independence through investments in stocks. We want to bring all investment data into one place and provide insights that will help you make better investment decisions.
          </div>
        </div>
        <div className="col-sm-8">
          <img src="bg.about@3x.png" alt="" className="about-us-bg img-fluid"/>
          <img src="bg.footer@3x.png" alt="" className="footer-bg img-fluid"/>
        </div>
        <div className="line"></div>
      </div>


      <div className="col-1 hide-sm"></div>

    </div>
  );
}

export default AboutUs;
