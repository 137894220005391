import React from "react";
import "./Components.css";
import AppStoreIcon from "./AppStoreIcon";

function Hero() {
  return (
    <div id="hero" className="hero row justify-content-md-center">
      <div className="side-margin hide-sm"></div>

      <div className="col image-copy">
        <img src="image.copy.svg" alt="" className="img-fluid" />
        <div>
          <AppStoreIcon />
        </div>
      </div>
      <div className="col-sm iphone">
        <div className="row">
          <img
            src="pattern.hero.svg"
            alt=""
            className="pattern-hero img-fluid hide-sm"
          />

          <div className="col-sm hide-sm">
            <img
              src="phone.hero.front-alt@3x.png"
              alt=""
              className="phone-hero-front"
            />
            {/* <img
              src="phone.hero.front-alt2.png"
              alt=""
              className="phone-hero-back"
            /> */}
          </div>

          <div className="col-sm hide-md mt-5">
            {/* <img
              src="pattern.hero.svg"
              alt=""
              className="pattern-hero img-fluid"
            />
            <img
              src="phone.hero.front-alt@3x.png"
              alt=""
              className="phone-hero-front"
            /> */}
          </div>
        </div>
      </div>
      <div className="col-2 hide-sm"></div>
    </div>
  );
}

export default Hero;
