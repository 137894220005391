import React from "react";
import "./Components.css";

function Features() {
  return (
    <div>
      <div className="row make-better-investme">
        <div className="col hide-sm">
          <div className="row title">
            <div className="side-margin">
              <img
                src="badge.2.feature.svg"
                alt="badge1"
                className="side-badge img-fluid"
              />
            </div>
            <div className="col-7">
              <p>Make better investment decisions</p>
            </div>
          </div>
        </div>

        <div className="col-sm hide-md">
          <img src="badge.2.feature.svg" alt="badge1" className="img-fluid" />
        </div>

        <div className="col-sm hide-md mt-4">
          <p>Make better investment decisions</p>
        </div>
      </div>

      <div className="row justify-content-md-center">
        <div className="col-md-10 col-sm features">
          <div className="row">
            <div className="col-sm feature-bg">
              {/* <div className="">
                <img
                  src="image.feature.1@2x.png"
                  alt=""
                  className="image-feature-1 img-fluid"
                />
              </div> */}
              <div className="feature-text">
                <div className="feature-text-bold">
                  Track all accounts in one place
                </div>
                <div className="feature-text-slim">
                  We support all major account types, including TFSA, RRSP,
                  Non-Registered
                </div>
              </div>
            </div>

            <div className="col-sm feature-bg">
              <div className="feature-text">
                <div className="feature-text-bold">
                  Holistic view of your portfolio: stocks, ETFs, crypto
                </div>
                <div className="feature-text-slim">
                  Our portfolio tracker gives you a clear picture of your
                  investment performance across all asset classes.
                </div>
              </div>
              {/* <div className="">
                <img
                  src="image.feature.2@2x.png"
                  alt=""
                  className="image-feature-2 img-fluid"
                />
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-sm feature-bg">
              <div className="feature-text">
                <div className="feature-text-bold">Portfolio analysis</div>
                <div className="feature-text-slim">
                  Our portfolio analysis tools help you make sure your
                  investments are aligned with your risk tolerance and goals.
                </div>
              </div>
              {/* <div className="hide-sm">
                <img
                  src="image.feature.3@2x.png"
                  alt=""
                  className="image-feature-3 img-fluid"
                />
              </div> */}
              {/* <div className="hide-md">
                <img
                  src="image.feature.3.mob@2x.png"
                  alt=""
                  className="image-feature-3 img-fluid"
                />
              </div> */}
            </div>

            <div className="col-sm feature-bg">
              {/* <div className="">
                <img
                  src="image.feature.4@2x.png"
                  alt=""
                  className="image-feature-4 img-fluid"
                />
              </div> */}
              <div className="feature-text">
                <div className="feature-text-bold">Events & News Alerts</div>
                <div className="feature-text-slim">
                  Our event and news alerts keep you informed of the latest
                  market developments so you can make informed investment
                  decisions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
