import React from 'react';
import './Components.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="row footer">


    <div className="side-margin hide-sm"></div>
      <div className="col-sm-10 footer-content">

        <div className="row footer-text">

          <div className="col-sm-5 text-left">
            <img src="Logo.MSF@1x.png" alt="logo" className="logo img-fluid"/>
          </div>
          <div className="col-sm-5  order-2 order-sm-1">
            <div className="row mt-3">
              <div className="col">
                <Link target="_blank" rel="noreferrer" to="/terms"><a>Terms Of Use</a></Link>
              </div>
              <div className="col">
                <Link target="_blank" rel="noreferrer" to="/privacy"><a>Privacy Policy</a></Link>
              </div>
              <div className="col">
                <a href="mailto:support@mystockfolio.com">Contact us</a>
              </div>
            </div>
          </div>
          <div className="col-sm-2 social order-1 order-sm-2">
            <div className="row">
              <div className="p-3">
                <a target="_blank"  rel="noreferrer" href="https://twitter.com/mystockfolio">
                  <img src="icon.twitter.svg" alt="twitter" className="footer-icon img-fluid"/>
                </a>
              </div>
              <div className="p-3">
              <a target="_blank"  rel="noreferrer" href="https://facebook.com/stockfolio/">
                <img src="icon.fb.svg" alt="fb" className="footer-icon img-fluid"/>
                </a>
              </div>
              {/* <div className="p-3">
                <img src="icon.instagram.svg" alt="fb" className="footer-icon img-fluid"/>
              </div> */}
            </div>
          </div>

        </div>
      </div>



    <div className="col-1 hide-sm"></div>
    </div>
  );
}

export default Footer;
