import "./Components.css";

const AppStoreIcon = (props) => {
  const clickHandler = () => {
    window.open(
      "https://apps.apple.com/ca/app/mystockfolio/id6451276859",
      "_blank"
    );
  };
  return (
    <img
      src="button.ios.white.app.store.svg"
      onClick={clickHandler}
      className="app-store"
    />
  );
};

export default AppStoreIcon;
