import React from 'react';
import './Components.css';

function GetStarted() {
  return (
    <div className="get-started row">
      <div className="side-margin hide-sm">
        <img src="badge.3.getstarted.svg" alt="badge1" className="side-badge img-fluid"/>
      </div>
      <div className="side-badge-sm hide-md">
        <img src="badge.3.getstarted.svg" alt="badge1" className="side-badge-sm img-fluid"/>
      </div>

      <div className="col-sm-10">
        <div className="row get-started-in-a-few">
          <p>Get started in a few minutes</p>
        </div>
        <div className="row we-support-all-major">
          We support all major Canadian banks and brokerages.
        </div>

        <div className="row steps">

          <div className="col-sm-4">
            <p className="step">
              <span className="one steps-text-size-1">
                1<br/>
              </span>
              <span className="one steps-text-size-2">
                CREATE<br/>
              </span>
              <span className="steps-text-size-3 steps-text">
                an account
              </span>

            </p>
          </div>
          <div className="col-sm-4">
            <p className="step">
              <span className="two steps-text-size-1">
                2<br/>
              </span>
              <span className="two steps-text-size-2">
                LINK<br/>
              </span>
              <span className="steps-text-size-3 steps-text">
                your investment accounts
              </span>



            </p>
          </div>
          <div className="col-sm-4">
            <p className="step">

            <span className="three steps-text-size-1">
              3<br/>
            </span>
            <span className="three steps-text-size-2">
              START<br/>
            </span>
            <span className="steps-text-size-3 steps-text">
              analyzing your portfolio
            </span>
            </p>
          </div>
        </div>


      </div>

    </div>

  );
}

export default GetStarted;
