import React from 'react';
import App from './App';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import { Route } from 'react-router-dom';

export const Routes = () => {
  return (
    <div>
        <Route exact path="/" component={App} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/terms" component={TermsOfUse} />
    </div>
  );
};