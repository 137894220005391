import React from "react";
import "./Components.css";
import Button from "./Button";

function Header() {
  return (
    <div className="header row align-items-center">
      <div className="side-margin hide-sm">
        <img
          src="logo.symbol.svg"
          alt="logo-symbol"
          className="side-badge img-fluid"
        />
      </div>
      <div className="col-2 hide-sm">
        <img src="Logo.MSF@3x.png" alt="logo" className="img-fluid" />
      </div>

      <div className="col-sm-3 hide-sm"></div>

      <div className="col-sm-3 hide-sm">
        <div className="row justify-content-end">
          <a
            href="https://app.mystockfolio.com"
            style={{ textDecoration: "none" }}
          >
            <Button />
          </a>
        </div>
      </div>

      <div className="col-2 hide-sm m-5"></div>

      <div className="col hide-md">
        <img src="Logo.header.svg" alt="logo-symbol" className="img-fluid" />
      </div>
    </div>
  );
}

export default Header;
