import React from 'react';
import './Components.css';

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: '', message: '', success: true};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    if(!this.state.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
      this.setState({ message: 'Invalid email!!', success: false });
      return;
    }else {
      this.setState({ value: '', message: 'Email added to waitlist: ' + this.state.value, success: true });
      const scriptURL = 'https://script.google.com/macros/s/AKfycbyRj4zXdLN1W8aOhXWC3XYZXiTDpSXR1mdZEAR1gaFKN5bXrE08MwelPdlmRCYxO9E/exec';
      const form = document.forms['waitlist'];
      fetch(scriptURL, { method: 'POST', body: new FormData(form)})
                       .then(response => console.log('Success!', response))
                       .catch(error => console.error('Error!', error.message));
    }


  }

  render() {
    return (
      <div id="download" className="row download align-items-center">
        <div className="col-1 hide-sm"></div>

        <div className="col-sm-10 p-0">

              <div className="its-free-get-start">
                {/*<div className="row" >
                  It's free, Get started today
                </div>
                <div className=" row download-btns justify-content-md-center">
                  <div className="col-6  justify-content-center">
                    <img src="button.ios.svg" alt="download-ios" className="button-ios img-fluid"/>
                  </div>
                  <div className="col-6  justify-content-center">
                    <img src="button.google.svg" alt="download-google" className="button-google img-fluid"/>
                  </div>
                </div>
                */}
                <div className="" >
                We are currently in closed beta
                </div>
                <div className="launch-text">
                Be the fist to know when we launch.
                </div>
                <div className="row d-none d-md-flex justify-content-center">
                  <div className="col-sm-8 mt-5">
                    <form className="form-inline" name="waitlist" method="post" onSubmit={this.handleSubmit}>
                      <input className="form-control-lg email-input" type="text" name="email" value={this.state.value}  onChange={this.handleChange} id="email" placeholder="Enter email address"/>
                      <button className="btn-lg btn-warning notify-button" id="notify-button" type="submit">NOTIFY ME!</button>
                    </form>
                    <div><p className={(this.state.success ? 'notify-message text-success' : 'notify-message text-danger')}> { this.state.message } </p> </div>
                  </div>
                </div>
                <div className="row d-md-none justify-content-center">
                  <div className="col-sm mt-5">
                    <form className="form-sm-inline notify-form" name="waitlist" method="post" onSubmit={this.handleSubmit}>
                      <input className="form-control-lg email-input" type="text" name="email" value={this.state.value}  onChange={this.handleChange} id="email" placeholder="Enter email address"/>
                      <button className="btn-lg notify-button" id="notify-button" type="submit">NOTIFY ME!</button>
                    </form>
                    <div><p className={(this.state.success ? 'notify-message text-success' : 'notify-message text-danger')}> { this.state.message } </p> </div>
                  </div>
                </div>
              </div>


              <img src="bg.image.download@3x.png" alt="download" className="hide-sm download-bg img-fluid"/>
              <img src="bg.image.download.sm@3x.png" alt="download" className="hide-md download-bg img-fluid"/>

      </div>
      <div className="col-1 hide-sm"></div>

      </div>
    );
  }
}

export default Download;
