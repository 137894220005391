import React from 'react';
import './Components.css';

class PrivacyPolicy extends React.Component {

  render() {
    return (
         <div>
           {window.location.href=process.env.PUBLIC_URL+"/privacy/index.html"}
         </div>
    );
  }
}

export default PrivacyPolicy;
